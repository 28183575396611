
import { defineComponent, ref } from "vue";
import { HQEvent } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "events",
  props: ["event", "allowEdit"],
  components: {},
  setup() {
    return {};
  },
});
