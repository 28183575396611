import { Modal } from "bootstrap";

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  const myModal = Modal.getOrCreateInstance(modalEl);
  myModal.hide();
};

const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  const myModal = Modal.getOrCreateInstance(modalEl);
  myModal.show();
};

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const parseVeeErrors = function (response) {
  const result = {};
  if ("data" in response) {
    response = response.data;
  }
  if ("violations" in response) {
    if (response.violations.length > 0) {
      for (let i = 0; i < response.violations.length; i++) {
        result[response.violations[i].propertyPath] =
          response.violations[i].message;
      }
    }
  }
  return result;
};

export { removeModalBackdrop, hideModal, showModal, parseVeeErrors };
