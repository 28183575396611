
import { defineComponent, ref, toRef } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "edit-event-modal",
  components: {},
  props: ["event"],

  setup(props, context) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editEventRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    let targetData = toRef(props, "event");
    const rules = ref({});

    const deleteEvent = async () => {
      if (targetData.value.id !== "") {
        try {
          loading.value = true;
          let response = await ApiService.delete(
            "api/v1/event/" + targetData.value.id
          );
          hideModal(editEventRef.value);
          context.emit("eventUpdated");
        } catch (e) {
          Swal.fire({
            text: "Error deleting event!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          loading.value = false;
        }
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (targetData.value.id !== "") {
            ApiService.update("api/v1/events", props.event.id, {
              ...targetData.value,
            })
              .then(() => {
                hideModal(editEventRef.value);
                Swal.fire({
                  text: "Event details saved!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
                context.emit("eventUpdated");
              })
              .finally(() => {
                loading.value = false;
              });
          } else {
            let params = { ...targetData.value };
            delete params.id;
            delete params.address.id;

            ApiService.post("api/v1/locations", params)
              .then(() => {
                context.emit("eventUpdated");
                hideModal(editEventRef.value);
                Swal.fire({
                  text: "Event Added!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              })
              .finally(() => {
                loading.value = false;
              });
          }
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      editEventRef,
      deleteEvent,
    };
  },
});
