
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import EventSummaryCard from "@/views/sbmx/manage_widgets/events/EventSummaryCard.vue";
import EditEventModal from "@/views/sbmx/modals/EditEventModal.vue";

export default defineComponent({
  name: "dashboard",
  components: { EventSummaryCard, EditEventModal },
  setup() {
    const store = useStore();
    const selectedEvent = ref({});

    watch(
      () => store.state.AuthModule.account.id,
      () => {
        return;
      }
    );

    onMounted(() => {
      setCurrentPageTitle("Event Calendar");
    });
    return {
      store,
      selectedEvent,
    };
  },
});
